import React from "react";
import { makeStyles, Typography, Box, Grid } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

function ServiceWidget({ service, isRight, width, isFirst }) {
  const classes = makeStyles(() => ({
    mainImage: {
      width: "100%",
      objectFit: "scale-down",
    },
  }))();

  const mainImage = () => {
    return (
      <Grid item xs={12} sm={6}>
        <img src={service.image} className={classes.mainImage} alt="" />
      </Grid>
    );
  };

  const mainBody = () => {
    return (
      <Grid
        item
        dir={"rtl"}
        xs={12}
        sm={6}
        align={isWidthUp("sm", width) ? "start" : "center"}
        style={
          isWidthUp("sm", width)
            ? {
              position: "relative",
              paddingRight: isRight ? 40 : 0,
              paddingLeft: !isRight ? 40 : 0,
            }
            : {
              position: "relative",
            }
        }
      >
        <Box>
          <Box
            style={
              isWidthUp("lg", width)
                ? {
                  marginBottom: 45,
                }
                : isWidthUp("sm", width)
                  ? {
                    marginBottom: 19,
                  }
                  : {
                    marginTop: 38,
                  }
            }
            className={
              "d-flex align-items-center " +
              (isWidthUp("sm", width) ? "" : "justify-content-center")
            }
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/svgs/checkmark.svg`}
              className="ml-3"
              alt=""
            />
            <Typography variant="h4">{service.title}</Typography>
          </Box>
          <Typography variant="body1">{service.description}</Typography>
          <Typography variant="body1">{service?.extras}</Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <Box
      style={
        isWidthUp("lg", width)
          ? //  web
          {
            marginTop: isFirst ? 122 : 217,
          }
          : isWidthUp("sm", width)
            ? // ipad
            {
              marginTop: isFirst ? 85 : 121,
            }
            : // mobile
            {
              marginTop: 60,
            }
      }
    >
      <Grid container alignItems="center">
        {mainImage()}
        {mainBody()}
      </Grid>
    </Box>
  );
}

export default withWidth()(ServiceWidget);
