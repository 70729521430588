const services = [
  {
    title: "اقبض بدري.. وقتي!",
    description:
      "يوفر الكارت للموظف ميزة استلام الجزء المستحق من الراتب عبر التطبيق لحظيًا في أي ساعة على مدار الأسبوع.",
    image: `${process.env.PUBLIC_URL}/assets/images/img_cardbenefits_1.png`,
  },
  {
    title: "تحويل المرتبات",
    description:
      "تمكين صاحب العمل من إرسال أجور جميع موظفي الشركة لكارت خزنة الخاص بكل موظف.",
    image: `${process.env.PUBLIC_URL}/assets/images/img_cardbenefits_2.png`,
  },
  {
    title: "السحب و الإيداع",
    description: "الإيداع بدون رسوم، والسحب لأول ٣ عمليات من أي بنك بدون رسوم ",
    extras: '*تطبق الشروط و الاحكام*',
    image: `${process.env.PUBLIC_URL}/assets/images/img_cardbenefits_3.png`,
  },
  {
    title: "تفاصيل الكارت بضغطة",
    description:
      "الاستعلام عن رصيد الكارت ومعرفة العمليات السابقة على الكارت من خلال التطبيق.",
    image: `${process.env.PUBLIC_URL}/assets/images/img_cardbenefits_4.png`,
  },
  {
    title: "مميزات أخرى",
    description: "يستعمل الكارت للتسوق من المحال التجارية وعبر الإنترنت",
    image: `${process.env.PUBLIC_URL}/assets/images/img_cardbenefits_6.png`,
  },
];

export default services;
